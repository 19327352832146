// Core
import React, { FC } from 'react'
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core'
import clsx from 'clsx'
// Components
import { FileList } from './components'
import FoldersSelector from '../folders-selector'
import { DialogTitle } from 'ui'
import DropArea from 'common/components/drop-area'
// Hooks
import { useTranslation } from 'react-i18next'
import { useUploadFiles } from '../../hooks'
// Styles
import useStyles from './upload-files-modal.styles'

type TProps = {
  children?: never
}

const UploadFilesModal: FC<TProps> = () => {
  const { t } = useTranslation()

  const classes = useStyles()
  const { open, filesList, loading, dropzoneProps, closeModal, folder, changeFolder } =
    useUploadFiles()

  return (
    <Dialog open={open} maxWidth="lg" fullWidth onClose={closeModal}>
      <DialogTitle onClose={closeModal}>{t('media.upload-files')}</DialogTitle>
      <DialogContent className={classes.root} dividers>
        <div className={clsx(classes.dropArea, { 'no-files': Boolean(!filesList.length) })}>
          <div style={{ maxWidth: !filesList.length ? '50%' : '100%', marginBottom: 16 }}>
            <FoldersSelector onChange={changeFolder} value={folder} />
          </div>
          <DropArea dropzoneProps={dropzoneProps} />
        </div>
        <div className={clsx(classes.filesList, { 'no-files': Boolean(!filesList.length) })}>
          <FileList isWaitingUpload={loading} data={filesList} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={closeModal} disabled={loading}>
          {t('global.done')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UploadFilesModal
