// Core
import React, { FC } from 'react'
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
// Context
import { ListContext } from 'core/data'
// Components
import TableItem from '../table-item'
import { Pagination } from 'core/components'
// Hooks
import { useRedirectsContext } from 'modules/redirects'
import { useSitesContext } from 'modules/sites'

type Props = {
  children?: never
}

const RedirectsTable: FC<Props> = (props) => {
  const { dataController } = useRedirectsContext()
  const { activeSite } = useSitesContext()
  const { t } = useTranslation()

  if (!dataController.data) return <CircularProgress />

  return (
    <TableContainer>
      <ListContext.Provider value={dataController}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('redirects.from')}</TableCell>
              <TableCell>{t('redirects.to')}</TableCell>
              <TableCell width={120}>{t('redirects.type')}</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Updated At</TableCell>
              <TableCell align="right">{t('table.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataController.data.map((item: any) => (
              <TableItem siteUrl={activeSite?.url || ''} key={item.id} data={item} />
            ))}
          </TableBody>
        </Table>
        <Pagination />
      </ListContext.Provider>
    </TableContainer>
  )
}

export default RedirectsTable
